<template>
    <img :src="logo" alt="Logo">
</template>

<script setup>
import {useAuthStore} from '@/store/auth'

const authStore = useAuthStore()
const logo = ref('/dealbuzzer_logo_black.svg')
const logoDefault = ref('/dealbuzzer_logo_black.svg')

authStore.$onAction(({name, after}) => {
    after(() => {
        if (name === 'setBranding') checkCustomLogo();
    })
})

onMounted(() => {
    checkCustomLogo()
})

function checkCustomLogo() {
    if (localStorage.getItem('organisation_branding') === '1' && localStorage.getItem('organisation_branding_data') !== "" && localStorage.getItem('organisation_branding_data') !== "null") {
        const {image} = JSON.parse(localStorage.getItem('organisation_branding_data'))
        logo.value = (image !== "" && image !== "null") ? '/storage/' + image : logoDefault.value
    } else {
        logo.value = logoDefault.value
    }
}
</script>
